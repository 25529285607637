// React
import React, { useMemo } from "react";
// React
// CSS
import styles from "./myStyle.module.css";
import "./App.css";
// CSS

import Particles from "react-tsparticles";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
const App = () => {
  //
  const memoParticle = useMemo(
    () => (
      <Particles
        options={{
          fullScreen: {
            enable: true,
            zIndex: 0,
          },
          particles: {
            number: {
              value: 10,
              limit: 20,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 4,
                color: "blueviolet",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "images/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.5,
                sync: false,
              },
            },
            size: {
              value: 30,
              random: true,
              anim: {
                enable: true,
                speed: 10,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 100,
              color: "#ffffff",
              opacity: 1,
              width: 1,
            },
            move: {
              enable: true,
              speed: 3,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                  enable: false,
                  force: 60,
                  smooth: 10,
                },
              },
              onClick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                lineLinked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 100,
                duration: 2,
                opacity: 1,
                speed: 2,
              },
              repulse: {
                distance: 200,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          backgroundMask: {
            enable: true,
            cover: {
              opacity: 0.5,
              color: {
                value: {
                  r: 0,
                  g: 0,
                  b: 0,
                },
              },
            },
          },
          retina_detect: true,
          fps_limit: 60,
          background: {
            // image: "url('https://particles.js.org/images/background3.jpg')",
          },
        }}
      />
    ),
    []
  );
  //
  return (
    <div className={`${styles.appContainer}`}>
      {memoParticle}
      <p>در حال آماده سازی پروژه ی بزرگ سیمفونی هستیم</p>
      <p
        style={{
          fontSize: "16px",
        }}
      >
        من یک توسعه دهنده هستم ، تو چطور ؟
      </p>
      <p
        style={{
          fontSize: "12px",
        }}
      >
        Symfoni {"->"} From PHP {"->"} Made By ❤ For All
      </p>
      <div
        className={styles.project}
        style={{
          marginTop: "2rem",
        }}
      >
        <p
          style={{
            paddingRight: "2rem",
          }}
        >
          FrontEnd :
        </p>
        <ProgressBar
          now={20}
          label={`${20}%`}
          animated
          style={{
            width: "20rem",
            direction: "ltr",
          }}
        />
      </div>
      <div className={styles.project} style={{}}>
        <p
          style={{
            paddingRight: "2rem",
          }}
        >
          BackEnd :
        </p>
        <ProgressBar
          now={30}
          label={`${30}%`}
          animated
          style={{
            width: "20rem",
            direction: "ltr",
          }}
        />
      </div>
      <div
        className={styles.project}
        style={{
          marginBottom: "2rem",
        }}
      >
        <p
          style={{
            paddingRight: "2rem",
          }}
        >
          _DevOps :
        </p>
        <ProgressBar
          now={15}
          label={`${15}%`}
          animated
          style={{
            width: "20rem",
            direction: "ltr",
          }}
        />
      </div>
      <p>از صبر و شکیبایی شما سپاسگذاریم</p>
    </div>
  );
};

export default App;
